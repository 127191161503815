import React, { FC, useEffect, useState } from 'react'
import Portal from './Portal'
import { MdClose } from 'react-icons/md'
import FAQ, { FAQType } from './FAQ/v_1/FAQ'


const FAQModal: FC<{ show:boolean,setShow:React.Dispatch<React.SetStateAction<boolean>>,  handleClose:()=>void, faqs?:FAQType[], projectInfo:{faqTitle?:string, faqs?:FAQType[],faqMandatory?:boolean } }> = ({ show,setShow, handleClose, faqs, projectInfo }) => {
  useEffect(()=>{
    if(projectInfo?.faqMandatory){
      setShow(true)
    }
  },[projectInfo])
  return (
    <>
      {show && (
        <Portal>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[9999] outline-none focus:outline-none">
            <div className="relative my-6 mx-auto w-[85%] lg:min-w-[400px] max-w-[85%] sm:max-w-[75%] md:max-w-[70%] lg:max-w-[550px] xl:max-w-[700px]">
              {/*content*/}
              <div className="relative flex flex-col w-full bg-white border-0 shadow-lg outline-none rounded-xl focus:outline-none ">
                {/*header*/}
                {
                  <div className="flex items-center justify-between p-4 border-b border-solid px-7 border-blueGray-200">
                    <h3 className="text-xl  lg:text-2xl font-black mb-0 text-[#5959FF] font-[Lato]">
                      {projectInfo?.faqTitle || 'Prompt Questions'}
                    </h3>
                    <span
                      className="text-2xl cursor-pointer"
                      onClick={handleClose}
                    >
                      <MdClose></MdClose>
                    </span>
                  </div>
                }
                {/*body*/}
                <div className="relative flex-auto max-h-[80vh] overflow-y-auto">
                  <div className="p-6 px-7">
                   <FAQ faqs={faqs} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-60 fixed inset-0 z-[999] bg-black"></div>
        </Portal>
      )}
    </>
  )
}

export default FAQModal
