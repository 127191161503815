import React, { FC } from 'react'
import { ProjectInfo1Props } from '../ProjectInfo1.types'
import parse from "html-react-parser";

const ProjectInfo2: FC<ProjectInfo1Props> = ({ projectInfo, next }) => {
  const { title, description, image } = projectInfo
  return (
    <>
    <div className='w-screen h-screen overflow-hidden max-w-[100vw] fixed top-0 left-0'>
      <img src={image?.src} className='object-cover object-center w-full h-full' style={{filter:"blur(1.3px)"}} />
    </div>
      <div 
        className="absolute z-20 flex flex-col items-center justify-center bg-[#fffa] w-4/6 left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%] h-auto gap-4 mx-auto lg:gap-8"
        style={{ fontFamily: 'Lexend Deca' }}
      >
        <div className="p-4 px-5 lg:p-8 lg:px-10 mb-[30px] lg:mb-[50px] flex flex-col gap-5 items-center text-justify leading-6">
          <span className="text-2xl font-medium"> {title} </span>

          <span className="font-[300]"> {parse(description || "")} </span>
        </div>

        {/* {image && (
          <img
            className="hidden lg:block object-cover object-center h-full mx-auto max-h-[350px] xl:max-h-[450px] p-4"
            src={image?.src}
          ></img>
        )} */}
        <div
        className="absolute rounded-full cursor-pointer bg-[color:var(--background-button)] font-[Sora] hover:bg-[color:var(--background-button-hover)] text-[#fff] bottom-[-30px] lg:bottom-[-50px] right-[40px] lg:right-[80px] w-20 h-20 lg:w-28 lg:h-28 m-auto flex items-center justify-center text-lg lg:text-xl font-semibold"
        onClick={next}
        >
        Start
      </div>
      </div>
    </>
  )
}

export default ProjectInfo2
