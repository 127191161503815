import React, { FC } from 'react'
import Form from 'react-bootstrap/Form'
const RadioInput: FC<any> = ({ label, titleClass, options, title, handleChange, name, labelClass, error, defaultValue, formClass, containerClass, disabled=false }: any) => {
  return (
    <Form className={formClass}>
        <div className={`text-lg font-medium ${titleClass?titleClass:""}`}>{title}</div>
      <div key={`default-radio`} className={`mb-3  ${containerClass ? containerClass : ""}`}>
        {options?.map((item:any)=>{
            return <div className='relative w-full'><Form.Check // prettier-ignore
            type={'radio'}
            label={item.title}
            aria-label={item.title}
            defaultValue={defaultValue}
            name={name}
            value={item.value}
            defaultChecked={defaultValue===item?.value}
            className={` text-[#383635] font-[Sora] focus:outline-none w-full p-2 px-4 flex gap-2 tracking-wide radio-custom-input  ${
              error && 'border-red-500'
            } ${labelClass}`}
            onChange={handleChange}
            disabled={disabled}
            bsSwitchPrefix="cc"
          />
          {item?.preview ? item.preview : <></>}
          </div>
        }) }
      </div>
    </Form>
  )
}

export default RadioInput
