import React, { useState } from 'react'
import { BsPlayCircle } from 'react-icons/bs'
import { useNavigate, useParams } from 'react-router-dom'

export const Tutorial = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [play, setPlay] = useState<boolean>(false)
  const next = () => {
    navigate(`/${id}/survey`)
  }
  return (
    <div className="relative flex items-center justify-center w-full h-screen">
      {play ? (
        <div className="w-[70%] h-[80%] mx-auto my-auto transition-all duration-300 ease-in-out">
          <video src={'https://ik.imagekit.io/2e5xfxvehcg/abc_3FobTO8kS.jpg'} className="w-full h-full" autoPlay controls />
        </div>
      ) : (
        <div className="w-[70%] h-[80%] mx-auto my-auto cursor-pointer relative" onClick={() => setPlay(true)}>
          <div className="w-full h-full flex justify-center items-center absolute bg-gradient-to-b from-[#1e1d1c83] to-[#1e1d1c83]">
            <BsPlayCircle className="w-12 h-12 fill-[#e5e5e5] hover:fill-[#fff]"></BsPlayCircle>
          </div>
          <img
            src={'/Assets/smt.jpeg'}
            className="object-cover object-center w-full h-full transition-all duration-300 ease-in-out"
          ></img>
        </div>
      )}

      <button
        className={`z-[999] font-[Ubuntu] fixed right-[40px] bottom-[130px] bg-[color:var(--background-button)] hover:bg-[color:var(--background-button-hover)] text-[#fff] px-6 py-4 w-20 h-20 rounded-full flex gap-2 items-center`}
        onClick={next}
      >
        {' '}
        Next
      </button>
    </div>
  )
}
