import React, { FC } from 'react'
import { ProjectInfo1Props } from '../ProjectInfo1.types'
import parse from "html-react-parser";

const ProjectInfo3: FC<ProjectInfo1Props> = ({ projectInfo, next }) => {
  const { title, description, image } = projectInfo
  return (
    <>
    <div className='w-screen h-screen overflow-hidden max-w-[100vw] fixed top-0 left-0'>
    <img src={image?.src} className='object-cover object-center w-full h-full' style={{filter:"blur(1.3px)"}} />
    </div>
    <div className="absolute top-0 bottom-0 left-0 right-0 pointer-events-none ql-editor bg-gradient-to-b from-transparent to-gray-900"></div>
      <div 
        className="absolute z-20 flex flex-col items-center justify-center text-white w-4/6 left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%] h-auto gap-4 mx-auto lg:gap-8"
        style={{ fontFamily: 'Lexend Deca' }}
      >
        <div className="flex flex-col items-center gap-5 p-2 px-5 leading-6 text-justify lg:px-10">
          <span className="text-3xl font-medium"> {title} </span>

          <span className="font-[300]"> {parse(description || "")} </span>
        </div>

        {/* {image && (
          <img
            className="hidden lg:block object-cover object-center h-full mx-auto max-h-[350px] xl:max-h-[450px] p-4"
            src={image?.src}
          ></img>
        )} */}
        <div
        className="rounded-md cursor-pointer bg-[color:var(--background-button)] font-[Sora] hover:bg-[color:var(--background-button-hover)] text-[#fff] mx-auto px-5 py-2 lg:px-10 lg:py-4 m-auto flex items-center justify-center text-lg lg:text-xl font-semibold"
        onClick={next}
        >
        Start
      </div>
      </div>
    </>
  )
}

export default ProjectInfo3
