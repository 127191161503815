import React, { useState, useEffect, useRef } from 'react'
import content from '../services/content.api'
import Input, { SelectInput, RadioInput, ImageUpload } from './Input'
import { MdClose, MdDelete } from 'react-icons/md'
import { AiTwotoneEdit } from 'react-icons/ai'
import { CgArrowLongLeft } from 'react-icons/cg'
import InputSwitch from './Input/InputSwitch'
import Toaster from './Toaster'
import TextEditor from './Input/TextEditor'
import ProjectInfo1 from './ProjectInfo/v_1'
import ProjectInfo2 from './ProjectInfo/v_2'
import ProjectInfo3 from './ProjectInfo/v_3'

const ProjectInfoSelection = ({ selectedProject, setSelectedProject, setEditType }: any) => {
  const [loading, setLoading] = useState(false)
  const [contentData, setContentData] = useState<any>(null)
  const [projectInfo, setProjectInfo] = useState<any>({})
  const [showPreview, setShowPreview] = useState(false)
  const [projectValues, setProjectValues] = useState<{ projectName?: string }>({})
  const [changeThere, setChangeThere] = useState<boolean>(false)
  const [fields, setFields] = useState<Array<any>>([
    { type: 'text', title: 'Title', value: '', name: 'title' },
    { type: 'texteditor', title: 'Project Description', value: '', name: 'description' },
    { type: 'image', title: 'Image', value: '', name: 'image' },
  ])
  const [errors, setErrors] = useState<any>({})
  const toasterRef = useRef<any>(null)

  const getContent = (id: string) => {
    if (!loading) {
      setLoading(true)
      content
        .getContentById(id)
        .then((res) => {
          setContentData(res.data)
          if (res.data.projectInfo) {
            setProjectInfo({
              ...res.data.projectInfo,
              image: res.data.projectInfo?.image?.src,
              faq: res.data.projectInfo?.faq || [],
            })
          }
          if (res?.data?.projectName) setProjectValues({ projectName: res.data.projectName })
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
          console.log(err)
        })
    }
  }

  useEffect(() => {
    if (selectedProject !== 'Project') {
      setLoading(false)
      getContent(selectedProject.toString())
    }
  }, [selectedProject])

  const updateContent = (preview:boolean) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    content
      .updateContent(selectedProject, {
        ...contentData,
        projectInfo: {
          ...projectInfo,
          image: { src: projectInfo?.image },
        },
      })
      .then((res) => {
        if (toasterRef.current) toasterRef.current.showToast('Project Info updated successfully', 'success')
        if(window && preview){window.open(`/${contentData.contentId}`, "_blank")}
      })
  }

  const handleInputChange = (e: any) => {
    setChangeThere(true)
    setErrors({})
    let temp = { ...projectInfo }
    if(e.target.name === "faqTitle") {
      if(e.target.value?.length <= 50) temp[e.target.name] = e.target.value
    }else temp[e.target.name] = e.target.value
    setProjectInfo({ ...temp })
  }
  const handleProjectChange = (e: any) => {
    setChangeThere(true)
    setProjectValues((t) => ({ ...t, [e.target.name]: e.target.value }))
  }

  const handleImg = (url: string) => {
    setChangeThere(true)
    setErrors({})
    let temp = { ...projectInfo }
    temp.image = url
    setProjectInfo({ ...temp })
  }

  // FAQ Handlers
  const handleFaqChange = (index: number, field: string, value: string) => {
    setChangeThere(true)
    const updatedFaq = [...(projectInfo.faq || [])]
    updatedFaq[index][field] = value
    setProjectInfo({ ...projectInfo, faq: updatedFaq })
  }

  const addFaq = () => {
    setChangeThere(true)
    const updatedFaq = [...(projectInfo.faq || []), { question: '', answer: '' }]
    setProjectInfo({ ...projectInfo, faq: updatedFaq })
  }

  const deleteFaq = (index: number) => {
    setChangeThere(true)
    const updatedFaq = [...(projectInfo.faq || [])]
    updatedFaq.splice(index, 1)
    setProjectInfo({ ...projectInfo, faq: updatedFaq })
  }
  const closePreview = () => setShowPreview(false)

  return (
    <div className="w-full h-full text-base" style={{ fontFamily: 'Lexend Deca' }}>
      <Toaster ref={toasterRef} />
      {!showPreview ? <>
      <div
        className="flex items-center justify-between gap-4 mb-[30px] cursor-pointer"
        onClick={() => {
          setEditType('')
          setSelectedProject('')
        }}
      >
        <div className="flex items-center justify-start flex-1 gap-4">
          <span className="text-xl">
            <CgArrowLongLeft />
          </span>
          <span className="text-base">Back to Projects</span>
        </div>
        <span className="ml-5 text-lg font-semibold">
          Project : <span className="font-medium">{contentData?.projectName}</span>
        </span>
        {changeThere && (
          <button
            className="bg-[color:var(--background-button)] font-[Sora] hover:bg-[color:var(--background-button-hover)] text-[#fff] px-6 py-2 rounded-md flex gap-2 items-center"
            onClick={updateContent(true)}
            type='button'
          >
            Save & Preview
          </button>
        )}
      </div>
      {!loading && contentData && (
        <>
          {/* <div className="justify-start text-xl flex my-4 items-center gap-4 bg-[#bec1c5] rounded-md p-3">
            {contentData?.projectName}
          </div> */}
          <div className="my-5">
            <Input
              type="text"
              title={`Project Name`}
              defaultValue={contentData?.projectName}
              value={contentData?.projectName}
              onChange={handleProjectChange}
              name={`projectName`}
              inputClassName={''}
              labelClassName={'bg-[#f3f7ff]'}
            />
          </div>
          <div className="w-full">
            <div className="my-5">
              <RadioInput
                title={'Layout'}
                name={'layout'}
                value={projectInfo?.layout}
                defaultValue={projectInfo?.layout}
                containerClass="flex gap-3 items-center justify-start"
                options={[
                  { title: 'Layout 1', value: 'layout1', preview : <Preview1 /> },
                  { title: 'Layout 2', value: 'layout2', preview : <Preview2 /> },
                  { title: 'Layout 3', value: 'layout3', preview : <Preview3 /> },
                ]}
                error={errors['layout']}
                handleChange={handleInputChange}
              />
              {/* <button className="px-3 py-1 text-base font-medium bg-[color:var(--background-button)] font-[Sora] hover:bg-[color:var(--background-button-hover)] text-[#fff] rounded-md my-3" onClick={()=>setShowPreview(true)}>Show Layout Preview</button> */}
            </div>
            {fields.map((field, index) => (
              <div className="my-6" key={index}>
                {field.type === 'text' ? (
                  <Input
                    type={field.type}
                    title={field.title}
                    defaultValue={projectInfo[field.name]}
                    name={field.name}
                    onChange={handleInputChange}
                    inputClassName={''}
                    labelClassName={'bg-[#f3f7ff]'}
                    value={projectInfo[field.name]}
                    error={errors[field.name]}
                  />
                ) : field.type === 'texteditor' ? (
                  <TextEditor
                    type={field.type}
                    title={field.title}
                    defaultValue={projectInfo[field.name]}
                    name={field.name}
                    onChange={handleInputChange}
                    inputClassName={''}
                    labelClassName={'bg-[#f3f7ff]'}
                    value={projectInfo[field.name]}
                    error={errors[field.name]}
                  />
                ) : field.type === 'textarea' ? (
                  <div className="relative w-auto tracking-wider">
                    <textarea
                      title={field.title}
                      defaultValue={projectInfo[field.name]}
                      name={field.name}
                      onChange={handleInputChange}
                      value={projectInfo[field.name]}
                      rows={4}
                      className={`text-[#383635] bg-[#00000000] autofill:!bg-[#00000000] focus:outline-none w-full p-4 px-4 border border-slate-500 tracking-wide rounded-lg font-[Sora] font-normal ${
                        errors[field.name] && 'border-red-500'
                      }`}
                    ></textarea>
                    <label
                      htmlFor={field.title}
                      className={`absolute font-[Sora] ${
                        projectInfo[field.name] ? 'top-[-12px] text-[#383635] ' : 'text-[#383635] top-[-4px]'
                      } ${
                        errors[field.name] && 'text-red-500'
                      } bg-[#f3f7ff] left-4 w-fit h-fit inset-0 px-1 capitalize transition-all ease-in-out duration-200`}
                    >
                      {field.title}
                    </label>
                    <span className="px-2 my-1 text-xs text-red-500">{errors[field.name]}</span>
                  </div>
                ) : field.type === 'image' ? (
                  <ImageUpload handleImg={handleImg} img={projectInfo[field.name]} />
                ) : null}
              </div>
            ))}
            <div className="w-full border border-dashed border-slate-300"></div>
            <div className="my-6">
              <div className="pb-3">
                <div className="flex items-center gap-4">
                  <h2 className="text-lg font-medium">Guiding Questions</h2>
                  <InputSwitch
                    value={projectInfo?.showFaq}
                    name={`showfaq-radio`}
                    onChange={() => {
                      setChangeThere(true)
                      setProjectInfo((t: any) => ({ ...t, showFaq: !t?.showFaq }))
                    }}
                    containerClassName="!w-fit"
                  />
                </div>
                <div className="mb-5 font-normal text-gray-500">
                  This section will appear on survey page on info click. Add some guiding questions regarding your project.
                </div>
              </div>
              <div className="pb-3">
                <div className="flex items-center gap-4">
                  <div className="text-base font-medium">Mandatory</div>
                  <InputSwitch
                    value={projectInfo?.faqMandatory}
                    name={`faqMandatory`}
                    onChange={() => {
                      setChangeThere(true)
                      setProjectInfo((t: any) => ({ ...t, faqMandatory: !t?.faqMandatory }))
                    }}
                    containerClassName="!w-fit"
                  />
                </div>
                <div className="mb-5 font-normal text-gray-500">
                  If mandatory is selected, guiding questions will be opened on load of survey page.
                </div>
              </div>
              <div className="mb-8">
                <Input
                  type={'text'}
                  title={'Section Title'}
                  defaultValue={projectInfo?.faqTitle || 'Guiding Questions'}
                  name={'faqTitle'}
                  onChange={handleInputChange}
                  inputClassName={'lg:w-1/2'}
                  labelClassName={'bg-[#f3f7ff]'}
                  value={projectInfo?.faqTitle || 'Prompt Questions'}
                  error={errors?.faqTitle}
                  disabled={!projectInfo?.showFaq}
                />
              </div>
              {(projectInfo.faq || []).map((faq: any, index: number) => (
                <div key={index} className="flex items-center gap-4 mb-5">
                  <Input
                    type="text"
                    title={`Question ${index + 1}`}
                    defaultValue={faq.question}
                    value={faq.question}
                    onChange={(e) => handleFaqChange(index, 'question', e.target.value)}
                    name={`question-${index + 1}`}
                    inputClassName={''}
                    wrapperClassName="disabled:opacity-70 disabled:cursor-not-allowed"
                    labelClassName={'bg-[#f3f7ff]'}
                    disabled={!projectInfo?.showFaq}
                  />
                  <Input
                    type="text"
                    title={`Hint `}
                    value={faq.answer}
                    onChange={(e) => handleFaqChange(index, 'answer', e.target.value)}
                    name={`answer-${index + 1}`}
                    inputClassName={'flex-1 '}
                    wrapperClassName={'flex-1 disabled:opacity-70 disabled:cursor-not-allowed'}
                    labelClassName={'bg-[#f3f7ff]'}
                    disabled={!projectInfo?.showFaq}
                  />
                  <button
                    className="text-2xl text-red-500 disabled:opacity-70 disabled:cursor-not-allowed"
                    disabled={!projectInfo?.showFaq}
                    onClick={() => deleteFaq(index)}
                  >
                    <MdDelete />
                  </button>
                </div>
              ))}
              <button
                className="px-4 py-2 text-white bg-blue-500 rounded-md disabled:opacity-70 disabled:cursor-not-allowed"
                onClick={addFaq}
                disabled={!projectInfo?.showFaq}
              >
                Add FAQ
              </button>
            </div>
            {changeThere && (
              <div className="flex justify-end gap-3 my-3 mb-6 items-center font-[Sora]">
                <button
                  className="px-6 py-2 border rounded-md border-slate-400 "
                  onClick={() => {
                    setEditType('')
                    setSelectedProject('')
                  }}
                >
                  Cancel
                </button>
                <button
                  className="bg-[color:var(--background-button)] font-[Sora] hover:bg-[color:var(--background-button-hover)] text-[#fff] px-6 py-2 rounded-md flex gap-2 items-center"
                  onClick={updateContent(false)}
                >
                  Save
                </button>
              </div>
            )}
          </div>
        </>
      )}
      </> :
      <></>
      // <div className='relative w-full h-full max-h-[75vh]'>
      //   <button className='absolute text-2xl -top-5 right-4' onClick={closePreview}><MdClose></MdClose></button>
      // {projectInfo?.layout === 'layout1' ? (
      //   <ProjectInfo1 projectInfo={contentData?.projectInfo} next={closePreview} />
      // ) : contentData?.projectInfo?.layout === 'layout2' ? (
      //   <ProjectInfo2 projectInfo={contentData?.projectInfo} next={closePreview} />
      // ) : contentData?.projectInfo?.layout === 'layout3' ? (
      //   <ProjectInfo3 projectInfo={contentData?.projectInfo} next={closePreview} />
      // ) : (
      //   <>s
      //   </>
      // )}
      // </div>
      }
    </div>
  )
}

export default ProjectInfoSelection

const Preview1 = () => { 
  return <div className="w-[300px] h-[100px] p-4 border rounded-lg shadow-md flex items-center space-x-4">
  <div className="flex-1 space-y-1.5">
    <div className="w-2/3 h-2 mb-1 bg-gray-300 rounded"></div>
    <div className="w-full h-1 bg-gray-200 rounded"></div>
    <div className="w-full h-1 bg-gray-200 rounded"></div>
    <div className="w-full h-1 bg-gray-200 rounded"></div>
  </div>
  <div className="w-16 h-16 bg-gray-300 rounded-lg "></div>
</div>

}

const Preview2 = () =>{
  return <div className="relative w-[300px] h-[100px] bg-gray-300 rounded-lg overflow-hidden">
  <div className="absolute inset-0 bg-gray-300"></div>

  <div className="absolute inset-0 flex items-center justify-center">
    <div className="w-2/3 p-4 space-y-2 bg-white bg-opacity-75 rounded-lg shadow-md">
    <div className="w-2/3 h-2 mb-1 bg-gray-300 rounded"></div>
    <div className="w-full h-1 bg-gray-200 rounded"></div>
    <div className="w-full h-1 bg-gray-200 rounded"></div>
    <div className="w-full h-1 bg-gray-200 rounded"></div>
    </div>
  </div>
</div>
}

const Preview3 = () =>{
  return <div className="relative w-[300px] h-[100px] bg-gray-300  rounded-lg overflow-hidden">
  <div className="absolute inset-0 bg-gray-100"></div>

  <div className="absolute inset-0 flex items-center justify-center">
    <div className="w-2/3 p-4 space-y-2 bg-opacity-75 rounded-lg shadow-md">
    <div className="w-2/3 h-2 mb-1 bg-gray-300 rounded"></div>
    <div className="w-full h-1 bg-gray-300 rounded"></div>
    <div className="w-full h-1 bg-gray-300 rounded"></div>
    <div className="w-full h-1 bg-gray-300 rounded"></div>
    </div>
  </div>
</div>
}