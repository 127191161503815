import React, { FC, useState } from 'react'
import Portal from './Portal'
import { MdClose } from 'react-icons/md'
import VideoPlayer from './VideoPlayer'
import { FiMinimize2 } from 'react-icons/fi'

const DemoVideosModal: FC<{ show: boolean; handleClose: () => void }> = ({ show, handleClose }) => {
  const [videos, setVideos] = useState([
    {
      title: 'Review node groups & nodes',
      videoUrl: 'https://ik.imagekit.io/2e5xfxvehcg/_abc_cs5vCxvYXt_ZQFgDhiOy.jpg',
      thumbnailUrl:
        'https://ik.imagekit.io/2e5xfxvehcg/_abc_CHby3Axln_pAA19FI1Fz.jpg',
    },
    {
      title: 'Build connections & assign ratings',
      videoUrl: 'https://ik.imagekit.io/2e5xfxvehcg/abc_cs5vCxvYXt.jpg',
      thumbnailUrl:
        'https://ik.imagekit.io/2e5xfxvehcg/abc_CHby3Axln.jpg',
    },
    {
      title: 'Review connections & ratings',
      videoUrl: 'https://ik.imagekit.io/2e5xfxvehcg/abc_9pBkj0pK1.jpg',
      thumbnailUrl:
        'https://ik.imagekit.io/2e5xfxvehcg/__abc_CHby3Axln_pAA19FI1Fz_-U99i1b2iA.jpg',
    },
  ])

  const [activeVideo, setActiveVideo] = useState<number | null>(null) // Track which video is enlarged

  return (
    <>
      {show && (
        <Portal>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[9999] outline-none focus:outline-none">
            <div className="relative my-6 mx-auto w-[85%] lg:min-w-[400px] max-w-[85%] md:max-w-[90%] lg:max-w-[650px] xl:max-w-[850px]">
              {/* Content */}
              <div className="relative flex flex-col w-full bg-white border-0 shadow-lg outline-none rounded-xl focus:outline-none">
                {/* Header */}
                <div className="flex items-center justify-between p-4 border-b border-solid px-7 border-blueGray-200">
                  <h3 className="text-xl lg:text-2xl font-black mb-0 text-[#5959FF] font-[Lato]">{'How it works'}</h3>
                  <span className="text-2xl cursor-pointer" onClick={handleClose}>
                    <MdClose />
                  </span>
                </div>

                {/* Body */}
                <div className="relative flex-auto max-h-[80vh] overflow-y-auto">
                  <div className="p-6 px-7">
                    {activeVideo === null ? <div className="text-xl font-semibold" style={{ fontFamily: 'Open Sans' }}>
                      Demo Videos
                    </div> : <></>}

                    {/* Enlarged Video */}
                    {activeVideo === 40 ? (
                      <div className="relative w-full max-h-[50vh] p-2">
                        <button
                          onClick={() => setActiveVideo(null)} // Minimize button
                          className="absolute z-10 flex items-center gap-1 p-1 px-3 text-white bg-gray-700 rounded-md top-2 right-2 hover:bg-gray-600"
                        >
                          <FiMinimize2 ></FiMinimize2>
                          Minimise 
                        </button>
                        <VideoPlayer
                          video={{ src: videos[activeVideo].videoUrl }}
                        />
                        <div className="px-2 py-3 text-base font-[Montserrat] font-semibold text-center">
                          {videos[activeVideo].title}
                        </div>
                      </div>
                    ) : (
                      /* Video Thumbnails */
                      <div className={`relative w-full p-2 grid grid-cols-1 gap-3 py-5 ${activeVideo === null ?'sm:grid-cols-2 lg:grid-cols-3':""}`}>
                        {videos.map((video, index) => {
                          if(activeVideo === null || activeVideo === index) return <div
                            className="flex flex-col w-full h-full gap-2 p-2 border rounded-md shadow-lg border-slate-300"
                            key={index}
                          >
                            {activeVideo !== null ?  <button
                          onClick={() => setActiveVideo(null)} // Minimize button
                          className="absolute z-10 flex items-center gap-1 p-1 px-3 text-white bg-gray-700 rounded-md top-2 right-2 hover:bg-gray-600"
                        >
                          <FiMinimize2 ></FiMinimize2>
                          Minimise 
                        </button> : <></>}
                            <div
                              className={`${activeVideo === null ? 'max-h-[150px] lg:max-h-[200px] xl:max-h-[250px]' : "max-h-[320px]"} h-full w-full cursor-pointer`}
                              onClick={() => setActiveVideo(index)} // Enlarge video
                            >
                              <VideoPlayer
                                video={{ src: video.videoUrl }}
                                thumbnail={{ src: video.thumbnailUrl }}
                              />
                            </div>
                            <div className="px-2 mb-3 text-base font-[Montserrat] font-semibold text-center">
                              {video.title}
                            </div>
                          </div>
})}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-60 fixed inset-0 z-[999] bg-black"></div>
        </Portal>
      )}
    </>
  )
}

export default DemoVideosModal
