import React, { FC } from 'react'
import { ProjectInfo1Props } from '../ProjectInfo1.types'
import parse from "html-react-parser";

const ProjectInfo1: FC<ProjectInfo1Props> = ({ projectInfo, next }) => {
  const { title, description, image } = projectInfo
  return (
    <div
      className="relative w-5/6 lg:w-9/12 h-full min-h-[200px] border-[0px] border-slate-500 bg-[#d2e4d8] mx-auto mt-[10%] xl:mt-[5%] flex flex-col lg:flex-row justify-center items-center gap-4 lg:gap-8"
      style={{ boxShadow: '10px 15px 20px -15px #00000080', fontFamily: 'Lexend Deca' }}
    >
      <div className="p-4 px-5 lg:p-8 lg:px-10 mb-[30px] lg:mb-[50px] flex flex-col gap-5 items-center text-justify leading-6">
        <span className="text-2xl font-medium text-left"> {title} </span>
        {image && (
          <img className="object-cover object-center h-full mx-auto max-h-[350px] p-4 lg:hidden" src={image?.src}></img>
        )}
        <span className="font-[300]"> {parse(description || "")} </span>
      </div>

      {image && (
        <img
          className="hidden lg:block object-cover object-center h-full mx-auto max-h-[350px] xl:max-h-[450px] p-4"
          src={image?.src}
        ></img>
      )}
      <div
        className="absolute rounded-full cursor-pointer bg-[color:var(--background-button)] font-[Sora] hover:bg-[color:var(--background-button-hover)] text-[#fff] bottom-[-30px] lg:bottom-[-50px] right-[40px] lg:right-[80px] w-20 h-20 lg:w-28 lg:h-28 m-auto flex items-center justify-center text-lg lg:text-xl font-semibold"
        onClick={next}
      >
        Start
      </div>
    </div>
  )
}

export default ProjectInfo1
